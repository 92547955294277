<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <h2 class="card-title">Meine Boards</h2>
                <div class="card-tools">
                    <button class="btn btn-primary btn-sm" @click="openModal()"><i class="fas fa-plus"></i> Neues Board</button>
                </div>
            </div>
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-md-12 d-flex justify-content-center text-center mx-auto">
                        <!-- <button class="btn bg-danger p-5" style="width: 100%; height:100%;"><b>{{ board.name }}</b></button> -->
                        <!-- <a href="#" class="bg-gray p-3">{{ board.name }}</a> -->
                        <ul class="boards-page-board-section-list" style="width: 100%;">
                            <li class="boards-page-board-section-list-item" v-for="board in boards" :key="board.id">
                                <router-link class="board-tile" :to="{name: 'boards.details', params: {id: board.id}}" :class="board.cover != null ? board.cover.content : ''">
                                    <span class="board-tile-fade"></span>
                                    <div class="board-tile-details is-badged">
                                        <div :title="board.name" dir="auto" class="board-tile-details-name">
                                            <div style="overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; display: -webkit-box; -webkit-line-clamp: 2;">
                                                {{ board.name }}
                                            </div>
                                        </div>
                                        <!-- <div class="board-tile-details-sub-container">
                                            <span class="board-tile-options">
                                                <span title="There is new activity on this board." class="board-tile-options-unread-indicator"></span>
                                                <span title="Click to star this board. It will be added to your starred list." class="icon-sm icon-star board-tile-options-star-icon"></span>
                                            </span>
                                        </div> -->
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="boardModal" title="Neues Board" ok-only ok-variant="primary" ok-title="Speichern" size="sm" @ok="handleOk">
            <div class="form-group">
                <label for="name" class="control-label">Name*</label>
                <input type="text" class="form-control form-control-sm" placeholder="Boardname" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}" />
                <has-error :form="form" field="name"></has-error>
            </div>

            <div class="form-group">
                <label for="tag" class="control-label">Hintergrund*</label>
                <ul class="new_board_list">
                    <li v-for="tag in tags" :key="tag.id" class="new_board_item">
                        <button type="button" :class="tag.content" @click="selectTag(tag.id)">
                            <span class="new_board_item_icon" v-if="tag.id == form.cover_id">
                                <span class="new_board_item_icon_self" role="img" aria-label="CheckIcon">
                                    <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73534 12.3223C6.36105 11.9162 5.72841 11.8904 5.3223 12.2647C4.91619 12.639 4.89039 13.2716 5.26467 13.6777L8.87678 17.597C9.41431 18.1231 10.2145 18.1231 10.7111 17.6264C10.7724 17.5662 10.7724 17.5662 11.0754 17.2683C11.3699 16.9785 11.6981 16.6556 12.0516 16.3075C13.0614 15.313 14.0713 14.3169 15.014 13.3848L15.0543 13.3449C16.7291 11.6887 18.0004 10.4236 18.712 9.70223C19.0998 9.30904 19.0954 8.67589 18.7022 8.28805C18.309 7.90022 17.6759 7.90457 17.2881 8.29777C16.5843 9.01131 15.3169 10.2724 13.648 11.9228L13.6077 11.9626C12.6662 12.8937 11.6572 13.8889 10.6483 14.8825C10.3578 15.1685 10.0845 15.4375 9.83288 15.6851L6.73534 12.3223Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                    </li>
                </ul>
                <has-error :form="form" field="name"></has-error>
            </div>

        </b-modal>
    </b-container>
</template>

<script>
export default {
    data(){
        return{
            boards: [],
            sharedBoards: [],
            form: new window.Form({
                name: '',
                cover_id: null, 
                cover_type: 'App\\Models\\Tag',
                // user_id: this.$auth.user().id,
            }),
            tags: [],
        }
    },

    methods:{

        handleOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.createBoard();
        },

        createBoard(){
            this.form
            .post("/boards")
            .then(() => {
                this.loadBoards();
                this.$bvModal.hide("boardModal");
                this.$swal({
                    icon: "success",
                    title: "Board gespeichert",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });                
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
            });
        },

        openModal(){
            this.form.clear();
            this.form.reset();
            this.form.cover_id = this.tags[0].id;
            this.$bvModal.show("boardModal");
        },

        selectTag(id){
            this.form.cover_id = id;
        },

        loadBoards(){
            this.axios
                .get("/profile/boards", {
                    params: {
                        per_page: 150,
                    }
                })
                .then((response) => {
                    this.boards = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadTags(){
            this.axios
                .get("/tags", {
                    params: {
                        per_page: 150,
                    }
                })
                .then((response) => {
                    this.tags = response.data.data;
                    this.form.cover_id = this.tags[0].id;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        // setupModal(){
        //     this.loadTags()
        // }
    },

    created() {
        this.loadBoards();
        this.loadTags();
    },

}
</script>

<style scoped>

.boards-page-board-section-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
}

.boards-page-board-section-list-item {
    margin: 0 2% 2% 0;
    padding: 0;
    transform: translate(0);
    width: 18%;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.board-tile {
    background-color: #97a0af;
    background-position: 50%;
    background-size: cover;
    color: #fff;
    line-height: 20px;
    padding: 8px;
    position: relative;
    text-decoration: none;
    border-radius: 3px;
    display: block;
}

.board-tile-fade {
    background-color: #00000026;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.board-tile:hover .board-tile-fade {
    background-color: #00000040;
}

.board-tile-details {
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
    position: relative;
}

.board-tile-details-name {
    word-wrap: break-word;
    display: inline-block;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 700;
    max-height: 40px;
    overflow: hidden;
    width: 100%;
}

.board-tile-details-sub-container {
    align-items: baseline;
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
}

.board-tile-options {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-left: 4px;
    overflow: hidden;
    position: relative;
    right: -4px;
}

.board-tile-options-unread-indicator {
    background: #fff;
    border-radius: 8px;
    display: inline-block;
    height: 12px;
    margin-right: 8px;
    opacity: .7;
    transition-duration: .15s;
    transition-property: right,opacity;
    width: 12px;
}

.board-tile-options-star-icon {
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    opacity: 0;
    right: 0;
    top: 0;
    -webkit-transform: translateZ(0);
    transition-duration: .15s;
    transition-property: color,background,opacity,transform,width,margin;
    width: 0;
}

.new_board_list{
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* padding-inline-start: 40px; */
}

.new_board_item{
    width: 40px;
    height: 32px;
    display: list-item;
    text-align: -webkit-match-parent;
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border: none;
}

.new_board_item button {
    align-items: center;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 0;
    outline: 0;
    padding: 0;
    position: relative;
    line-height: 0;
    width: 100%;
    cursor: pointer;
    border: none;
}

.new_board_item_icon{
    position: relative;
    z-index: 1;
    flex-basis: 100%;
    font-size: 16px;
    /* height: 24px; */
    /* line-height: 24px; */
    display: inline-block;
}

.new_board_item_icon_self{
    color: rgb(255, 255, 255);
    display: inline-block;
    fill: inherit;
    flex-shrink: 0;
    line-height: 1;
}
</style>